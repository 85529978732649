<template>
  <div
    class="d-flex flex-wrap justify-content-center mb-1"
  >
    <p>Please enter the email address where you wish to receive the jobs.</p>
    <div class="w-100 d-flex flex-row flex-gap-md align-items-center">
      <b-form-input
        v-model="emailInput.value"
        required
        type="email"
        class="w-full"
        :placeholder="placeholder"
      />

      <b-button
        :disabled="!emailInput.isValid"
        variant="primary"
        size="md"
        @click="submitForm"
      >Submit</b-button>
    </div>

    <b-modal
      v-model="modals.account_not_exist.state"
      centered
      hide-footer
      title="Share Job"
    >
      <div class="d-flex flex-column">
        <div>
          The <b> {{ emailInput.value }} </b> that you have entered doesn’t
          have an existing account our system. We have sent you an email to create your account to view your favorited jobs and
          proceed to complete your application at your own ease.
        </div>

        <div class="d-flex justify-content-center">
          <b-button
            class="mt-2"
            variant="primary"
            size="lg"
            @click="modals.account_not_exist.state = !modals.account_not_exist.state"
          >Close</b-button>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Your email address',
    },
    value: {
      type: String,
      default: null,
    },
    jobId: {
      type: [String, Array],
      default: null,
    },
  },

  data() {
    return {
      emailInput: {
        value: null,
        isValid: false,
      },
      modals: {
        account_not_exist: { state: false },
      },
    }
  },
  watch: {
    // * both works but the latter should be more efficient
    // emailInput: {
    //   deep: true,
    //   handler(newValue, oldValue) {
    //     if (this.$checkEmail(newValue.value).state) {
    //       this.emailInput.isValid = true
    //       this.$emit('input', newValue.value)
    //     } else {
    //       this.emailInput.isValid = false
    //     }
    //   },
    // },
    'emailInput.value': function (newValue, oldValue) {
      if (this.$checkEmail(newValue).state) {
        this.emailInput.isValid = true
        this.$emit('input', newValue)
      } else {
        this.emailInput.isValid = false
      }
    },

    value(newValue, oldValue) {
      this.emailInput.value = newValue
    },
  },

  mounted() {
    this.emailInput.value = this.value
  },

  methods: {
    submitForm() {
      this.$emit('generateCode', null)
      // return
      // this.$http
      //   .post('/api/user/kiosk-share-job', {
      //     form_data: {
      //       key: process.env.VUE_APP_RANDOM,
      //       email_address: this.emailInput.value,
      //       job_id: this.jobId,
      //     }
      //   })
      //   .then(response => {
      //     const { success } = response.data

      //     if (!success) {
      //       this.$set(this.modals.account_not_exist, 'state', true)
      //     }
      //   })
    },
  },
}
</script>

<style>

</style>
